/* You can add global styles to this file, and also import other style files */
.mat-elevation-z8 table tr:nth-child(even){
  @import "~@angular/material/prebuilt-themes/indigo-pink.css";

    background: #f2f2f2;
  }
  .btn-primary-type {
    background: var(--dark-red);
  }
  
  .btn-primary-type:disabled {
    border-radius: 40px;
    background: var(--gray-03);
    color: #495057;
    border: 0;
  }
  
  .btn-primary-type:disabled:hover {
    background: var(--gray-03);
    box-shadow: none;
    color: var(--black-01);
  }
  
  .btn-primary-type:hover,
  .btn-primary-type:active,
  :not(.btn-check) + .btn:active,
  .btn-primary-type:first-child:active {
    background: var(--dark-red);
    box-shadow: 2px 6px 4px 0 rgba(0, 0, 0, 0.12);
    color: var(--white);
  }
  .required {
    color: red;
    margin-left: 1px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 2px 5px;
  border: 0 none;
  color: #4b5563;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 43px;
}
.p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  font-size: 14px;
}

.p-inputtext {
    font-size: 14px;
}
.p-dropdown input{
  height: 40px;
}
.p-dropdown-panel .p-dropdown-header {
  padding: 5px 20px 5px 5px;
  border-bottom: 1px solid #e5e7eb;
  color: #374151;
  background: #f9fafb;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.filter-card{
  background: #fff;
  min-height: 50px;
  position: relative;
  margin-bottom: 24px;
  border: 1px solid #f2f4f9;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  -webkit-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  display: flex;
  padding: 8px;
  h5{
      align-items: baseline;
  }
}