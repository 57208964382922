.notice-board {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
  .notice-body {
    font-size: 13px;
    padding: 0px 0px 5px 10px;
    p {
      margin-bottom: 0px;
    }
    .notice-heading {
      margin: 0px 0px 0px 0px;
    }
  }
}
