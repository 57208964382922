// .body .card-body .card {
//     overflow-y: auto;
// }

// .card-body .form-control,
// .form-select {
//     font-family: 'Inter', sans-serif;
//     height: 50px !important;
//     color: gray;
//     font-size: 13px;
//     font-weight: 600;
// }

// .form-section .form-control,
// .form-select {
//     font-family: 'Inter', sans-serif;
//     height: 50px !important;
//     color: gray;
//     font-size: 13px;
//     font-weight: 600;
// }

// .form-section input {
//     margin-top: 5px !important;
// }

// .form-section select {
//     margin-top: 5px !important;
// }

// .form-section select {
//     margin-top: 5px !important;
// }

// form select,
// input {
//     margin-top: 20px;
// }

// .modal-body form select {
//     margin-top: 0px !important;
// }

// .modal-body form input {
//     margin-top: 0px !important;
// }

// .modal-body label {
//     font-family: 'Inter', sans-serif;
//     color: gray;
//     font-size: 13px;
//     font-weight: 700;
// }

// .action-buttons {
//     margin-top: 40px;
// }

// .action-buttons button {
//     font-size: 13px;
//     font-weight: 600;
//     padding: 12px 0;
//     width: 120px;
//     margin: 5px 10px;
// }

// .action-buttons button:hover {
//     background-color: transparent;
// }

// .action-buttons button .bi {
//     font-size: 18px;
//     font-weight: 600;
// }

// .addMore-btn {
//     background-color: #4FA14D !important;
//     color: white !important;
// }

.save-btn {
    background-color: #33306D !important;
    color: aliceblue !important;
    margin-top: 10px;
}

.cancel-btn {
    background-color: #FFF5F8 !important;
    color: #F1416C !important;
}

// .remove-btn {
//     background-color: red !important;
//     color: #fff !important;
//     margin-top: 25px;
// }

// .x-btn {
//     font-size: small;
//     background-color: #F53B00;
//     color: white;
// }

// .heading h5 {
//     font-weight: 600;
// }

// .heading .sub-heading {
//     color: gray;
//     font-size: 14px;
// }

// .heading .sub-heading span {
//     color: #5014D0;
// }

// .card-body .heading {
//     font-weight: 600;
// }

// .card-body .card {
//     border: none;
// }

// .card-body table {
//     font-size: 14px;
// }

// .card-body table tr td {
//     color: gray;
// }

// .card-body table tr {
//     transition: transform 0.5s;
//     line-height: 35px;
//     min-height: 35px;
//     height: 35px;
// }

// .table-action-btn button {
//     border-radius: 50%;
//     width: 35px;
//     color: #fff;
//     opacity: .9;
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//     border: none;
// }

// .table-action-btn .edit {
//     background-color: #33306D;
// }

// .table-action-btn .delete {
//     background-color: red;
// }

// .ngx-pagination .current,
// .btn-info {
//     background: #33306D !important;
//     border: transparent !important;
//     border-radius: 5px !important;
// }

// .ngx-pagination .current:hover {
//     background: #33306D !important;
//     border-radius: 5px !important;
//     border: transparent !important;
// }

// /* Scroll */
// ::-webkit-scrollbar {
//     width: 10px;
// }

// ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey;
//     border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb {
//     background: silver;
//     border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb:hover {
//     background: silver;
// }

// /* File upload */
// .drop-container {
//     position: relative;
//     display: flex;
//     gap: 10px;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 20px;
//     padding: 20px;
//     border-radius: 10px;
//     border: 2px dashed #555;
//     color: #444;
//     cursor: pointer;
//     transition: background .2s ease-in-out, border .2s ease-in-out;
// }

// .drop-container:hover {
//     background: #eee;
//     border-color: #111;
// }

// .drop-container:hover .drop-title {
//     color: #222;
// }

// .drop-title {
//     color: #444;
//     font-size: 20px;
//     font-weight: bold;
//     text-align: center;
//     transition: color .2s ease-in-out;
//     margin-top: 30px;
// }

// .uploaded-image-border {
//     border: 2px dashed limegreen;
// }

// .drop-title-uploaded {
//     color: limegreen;
//     font-size: 20px;
//     font-weight: bold;
//     text-align: center;
//     transition: color .2s ease-in-out;
//     margin-top: 30px;
// }

// .invalid-feedback-thumbnail {
//     color: #dc3545;
//     font-size: 0.875em;
// }

// /* Right side modal */
// .tab-content .modal-dialog {
//     margin-right: 0 !important;
//     width: 80vw !important;
//     animation: fadeInRight 0.1s ease-in-out;
// }

// @keyframes fadeInRight {
//     from {
//         opacity: 0;
//         transform: translateX(300px);
//     }

//     to {
//         opacity: 1;
//     }
// }

// .form-section .sub-header {
//     background-color: rgb(123, 115, 158);
//     color: #fff;
//     padding: 5px;
// }

.form-section [type='udiseTab'] {
    border: 1px solid silver;
    border-radius: 5px;
    background-color: #fff;
}

.main-section  [type='udiseTab'] {
    background-color: #fff;
}

// .multiselect-dropdown .dropdown-btn {
//     border: none !important;
//     /* background: aqua !important; */
// }